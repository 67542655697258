import DashboardList from "../../Components/Layouts/DashboardList";
import { useGetDeletedAlbumListQuery } from "../../redux/slice/album/albumApiSlice";
import {
  usePermanentDeleteMediaMutation,
  useRestoreDeletedMediaMutation,
} from "../../redux/slice/media/mediaApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteMediaMutation();
  const [onRestore, { isLoading: restoring }] =
    useRestoreDeletedMediaMutation();

  let loading = permaDeleting || restoring;
  const tableConfig = {
    title: "Deleted Media",
    applyFilter: false,
    title: "Media",
    filterOptionTitle: "Team Type",
    filterOptions: ["package"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "title",
    updatePath: "/media/update",
    updateParentPath: "/media/update",
    addPath: "/media/add",
    noDataMessage: "No album exists",
    updateLinkColumn: "title",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
        .unwrap()
        .then((data) => console.log("restored", data))
        .catch(() => console.error("restore error"));
    },
    loading,
  };

  const {
    data: list,
    isLoading: albumListLoading,
    isError: isAlbumListError,
    isSuccess,
  } = useGetDeletedAlbumListQuery();

  const datatolist = {
    ...(list && {
      data: list.map((itm) => ({
        ...itm,
        ...(itm.content.length > 0 && {
          children: itm.content.map((el, idx) => {
            return {
              ...el,
              title: el.caption
                ? `--- ${el.caption}`
                : `--- Media Item ${idx + 1}`,
            };
          }),
        }),
      })),
    }),
    dataById: undefined,
    error: isAlbumListError,
    loading: albumListLoading,
    success: isSuccess,
  };

  return (
    <DashboardList
      getList={useGetDeletedAlbumListQuery()}
      tableConfig={tableConfig}
      dataToList={datatolist}
      isDifferentList
    />
  );
}
export default DeletedList;
