import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import MediaForm from "../../Components/Forms/MediaForm";
import { useEffect } from "react";
import { useGetMediaByIdQuery, useUpdateMediaMutation } from "../../redux/slice/media/mediaApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetMediaByIdQuery;

  const [
    updateItinerary,
    {
      data: updatedMedia,
      isLoading: updating,
      isError: updateMediaError,
      error,
    },
  ] = useUpdateMediaMutation("updateMedia");

  const {
    data: media,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateMediaError,
    dataById: successById && media,
  };
  const selectedForm = {
    data: undefined,
    loading: loadingById || updating,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <MediaForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          updateEntityInfo: updateItinerary,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
