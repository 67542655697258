import DashboardList from "../../Components/Layouts/DashboardList";
import { useDeleteMediaByIdMutation } from "../../redux/slice/media/mediaApiSlice";
import { useGetAlbumListQuery } from "../../redux/slice/album/albumApiSlice";

function List() {
  const [onDelete] = useDeleteMediaByIdMutation("deleteEntityById");

  const tableConfig = {
    title: "Media",
    filterOptionTitle: "Team Type",
    filterOptions: ["package"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "title",
    updatePath: "/media/update",
    updateParentPath: "/media/update",
    addPath: "/media/add",
    noDataMessage: "No album exists",
    updateLinkColumn: "title",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  const {
    data: list,
    isLoading: albumListLoading,
    isError: isAlbumListError,
    isSuccess,
  } = useGetAlbumListQuery();

  const datatolist = {
    ...(list && {
      data: list.map((itm) => ({
        ...itm,
        ...(itm.content.length > 0 && {
          children: itm.content.map((el, idx) => {
            return {
              ...el,
              title: el.caption
                ? `--- ${el.caption}`
                : `--- Media Item ${idx + 1}`,
            };
          }),
        }),
      })),
    }),
    dataById: undefined,
    error: isAlbumListError,
    loading: albumListLoading,
    success: isSuccess,
  };

  return (
    <DashboardList
      getList={useGetAlbumListQuery()}
      tableConfig={tableConfig}
      dataToList={datatolist}
      isDifferentList
    />
  );
}

export default List;
